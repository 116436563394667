{
  "company.name": "MVE",
  "company.name&status": "MVE SAM",
  "company.address": "31, Avenue Princesse Grace - 98000 Monaco",
  "company.phone": "+377 97 97 51 03",
  "contact-us": "Contattaci",

  "header.open-main-menu": "Apri menu",
  "header.close-main-menu": "Chiudi menu",
  "header.nav.home": "Home",
  "header.nav.services": "Servizi",
  "header.nav.resources": "Risorse",
  "header.nav.about": "Chi siamo",
  "header.nav.contact": "Scopri di più",
  "header.nav.get-started": "Inizia ora",
  "header.calls-to-action.send-mail": "Inviaci una mail",
  "header.calls-to-action.call-sales": "Chiama vendite",
  "header.company.certifications": "Certificazioni",
  "header.company.certifications.description": "Scopri di più sui nostri valori aziendali e certificazioni",
  "header.company.careers": "Carriere",
  "header.company.careers.description": "Stai cercando la tua prossima opportunità di carriera? Vedi tutte le nostre posizioni aperte",
  "header.company.support": "Contatti",
  "header.company.support.description": "Contattate il nostro team in caso di domande o richieste",
  "header.company.fonds-bleu": "Fond Bleu",
  "header.company.fonds-bleu.description": "Il Fond Bleu si basa sulla piattaforma Extended Monaco per l'Impresa, uno strumento per supportare la transizione digitale delle aziende monegasche.",
  "header.new": "Nuovo",
  "header.resources.blog": "Risorse",
  "header.resources.blog.description": "Scopri tutte le ultime notizie da MVE Monaco.",
  "header.resources.events": "Eventi",

  "hero.hiring": "Stiamo assumendo",
  "hero.open-positions": "Vedi posizioni aperte",
  "hero.title": "Proteggete i vostri sistemi, aumentate le vostre prestazioni",
  "hero.title2": "Esperti di cybersecurity e intelligenza artificiale",
  "hero.subtitle": "Da oltre dieci anni MVE è specializzata in cybersecurity, domotica, videosorveglianza e gestione di progetti sensibili. Disponiamo di tutte le risorse, le competenze e le certificazioni necessarie per supportare i nostri clienti e soddisfare le loro esigenze.",
  "hero.get-started": "Inizia ora",
  "hero.learn-more": "Scarica il nostro deck",
  "hero.pdf-name": "MVE_SAM_DESK_EN.pdf",

  "banner.title": "I nostri dipendenti altamente qualificati e ben formati lavorano su cybersecurity, networking, domotica... Nell'era dell'informazione, le installazioni classiche non sono più sufficienti per garantire le prestazioni e la riservatezza di cui i nostri clienti hanno bisogno.",
  "banner.author": "Cristiano Toso",
  "banner.roles": "CEO & Fondatore di MVE",

  "services": "I nostri servizi",
  "services.title": "Tutta la conoscenza di MVE",
  "services.subtitle": "MVE, il tuo partner per la cybersecurity e la sicurezza.",
  "services.cyber-security": "Cybersecurity",
  "services.cyber-security.description": "Protezione dei sistemi informatici e delle reti da furti, danni o accessi non autorizzati.",
  "services.network": "Networking",
  "services.network.description": "Salvaguardia di beni fisici e digitali attraverso il monitoraggio, il controllo degli accessi e la mitigazione dei rischi.",
  "services.smart-home": "Casa intelligente",
  "services.smart-home.description": "Progettazione e installazione di sistemi smart home, come reti di dati, sistemi di sicurezza e attrezzature audiovisive.",
  "services.cloud": "Integratore di cloud",
  "services.cloud.description": "Soluzioni di cloud computing sicure e personalizzate per l'archiviazione, la gestione e l'elaborazione dei dati.",
  "services.blockchain": "Blockchain",
  "services.blockchain.description": "Sicurezza e decentramento delle transazioni digitali attraverso blocchi collegati crittograficamente.",
  "services.devops": "DevOps",
  "services.devops.description": "Ottimizzazione dei processi di sviluppo e operazione per un'integrazione e una consegna continue, garantendo velocità e affidabilità durante tutto il ciclo di vita dell'applicazione.",

  "contact.title": "Contattaci",
  "contact.subtitle": "Compila il modulo per tutte le tue richieste, il nostro team ti contatterà al più presto.",
  "contact.form.firstname": "Nome",
  "contact.form.lastname": "Cognome",
  "contact.form.company": "Azienda",
  "contact.form.email": "Email",
  "contact.form.phone": "Numero di telefono",
  "contact.form.message": "Messaggio",
  "contact.form.agree-to-policies": "Accetto le politiche",
  "contact.form.agree-to-policies-validation-1": "Selezionando questo, accetti la nostra",
  "contact.form.agree-to-policies-validation-2": "privacy",
  "contact.form.agree-to-policies-validation-3": "politica",
  "contact.form.submit": "Parliamo",

  "about-us.title": "Rafforziamo la nostra sicurezza online.",
  "about-us.description": "Siamo un'azienda di cybersecurity focalizzata sull'aiutare le aziende a proteggere i loro dati e mantenere la loro sicurezza online. Crediamo nel creare un ambiente digitale più sicuro attraverso soluzioni e servizi innovativi.",
  "about-us.mission": "La nostra missione",
  "about-us.mission.description-1": "La nostra missione è fornire servizi di cybersecurity di alto livello che salvaguardano le informazioni e i sistemi dei nostri clienti da tutte le minacce.",
  "about-us.mission.description-2": "Mira a costruire fiducia e affidabilità attraverso il nostro lavoro, rendendo il mondo digitale più sicuro per tutti.",
  "about-us.stats.first.value": "24/7",
  "about-us.stats.first.label": "Supporto online",
  "about-us.stats.second.value": "50+",
  "about-us.stats.second.label": "Ingegneri per te",
  "about-us.stats.third.value": "10y+",
  "about-us.stats.third.label": "Esperienza sul campo",
  "about-us.team": "Il nostro team",
  "about-us.team.description": "Siamo un gruppo dinamico di individui appassionati di ciò che facciamo e dedicati a fornire i migliori risultati per i nostri clienti.",
  "about-us.values": "I nostri valori",
  "about-us.values.description": "I nostri valori riflettono la nostra dedizione a fornire soluzioni di cybersecurity di alta qualità, mantenendo un approccio centrato sul cliente e innovando continuamente nel nostro campo. Crediamo nel potere della tecnologia e ci impegniamo ad utilizzarlo per rendere il mondo un posto più sicuro.",
  "about-us.values.first.name": "Push per implementare.",
  "about-us.values.first.description": "MVE con il supporto di Lutron e Crestron costruisce sistemi audio/video/domotici professionali e facili da usare.",
  "about-us.values.second.name": "Certificati di Cyber.",
  "about-us.values.second.description": "MVE implementa software all'avanguardia e auditor certificati per garantire una rete protetta.",
  "about-us.values.third.name": "Uso semplice.",
  "about-us.values.third.description": "Garantiamo un ambiente stabile e user-friendly.",
  "about-us.values.fourth.name": "Sicurezza avanzata.",
  "about-us.values.fourth.description": "Sistema di gestione video, ANPR, riconoscimento facciale, strumenti biometrici a tuo supporto.",
  "about-us.values.fifth.name": "Ambiente potente.",
  "about-us.values.fifth.description": "Potenziamento di persone, aziende e società con tecnologia video basata sui dati.",
  "about-us.values.sixth.name": "Cloud.",
  "about-us.values.sixth.description": "MVE offre robusti servizi cloud che forniscono soluzioni di archiviazione sicure, scalabili e affidabili per le esigenze di dati e tecnologia video. Partner Monaco Cloud.",

  "footer.quote": "Rafforziamo la nostra sicurezza online.",
  "footer.all-rights-reserved": "Tutti i diritti riservati.",
  "footer.services.title": "Servizi",
  "footer.company.title": "Azienda",
  "footer.company.certifications": "Certificazioni",
  "footer.company.careers": "Carriere",
  "footer.company.support": "Contatti",
  "footer.company.about-us": "Chi siamo",
  "footer.company.fond-bleu": "Fond Bleu",
  "footer.legal.title": "Legale",
  "footer.legal.legal-info": "Informazioni legali",
  "footer.legal.terms-of-use": "Termini di utilizzo",
  "footer.legal.cookies-policy": "Politica sui cookie",
  "footer.resources.blog": "Risorse",
  "footer.resources.events": "Eventi",
  "footer.resources.title": "Risorse",

  "careers.title": "Lavoriamo insieme.",
  "careers.subtitle": "Vuoi unirti al team dei sogni? Compila questo modulo e ti risponderemo il più presto possibile",
  "careers.form.firstname": "Nome",
  "careers.form.lastname": "Cognome",
  "careers.form.email": "Email",
  "careers.form.company": "Azienda",
  "careers.form.phone": "Numero di telefono",
  "careers.form.optional": "Opzionale",
  "careers.form.message": "Messaggio",
  "careers.form.max-length": "Max 500 caratteri",
  "careers.form.submit": "Invia messaggio",

  "error.404": "404",
  "error.title": "Pagina non trovata",
  "error.description": "Ci dispiace, non abbiamo trovato la pagina che stai cercando.",
  "error.back-home": "Torna alla home",

  "certifications.title": "Certificazioni",
  "certifications.subtitle": "Tutto il know-how di MVE da ingegneri qualificati",
  "certifactions.certificate-1": "Fornitore di audit (PASSI)",
  "certifactions.certificate-2": "Partner Milestone",
  "certifactions.certificate-3": "Partner Chainalysis",
  "certifactions.certificate-4": "Partner Monaco Cloud",
  "certifactions.certificate-5": "Certificazione Lutron",
  "certifactions.certificate-6": "Certificazione Crestron",

  "cybersecurity.title": "La sicurezza informatica è un problema di sicurezza collettiva.",
  "cybersecurity.subtitle": "Un team di revisori ed esperti di sicurezza informatica per supportarti nelle aree di Prevenzione - Protezione - Reazione e Formazione.",
  "cybersecurity.firstdate.date": "31 luglio 2020",
  "cybersecurity.firstdate.datetime": "2020-08",
  "cybersecurity.firstdate.title": "Certificazione PASSI",
  "cybersecurity.seconddate.date": "15 novembre 2020",
  "cybersecurity.seconddate.datetime": "2020-11",
  "cybersecurity.seconddate.title": "Primo Contratto Cyber",
  "cybersecurity.thirddate.date": "8 gennaio 2021",
  "cybersecurity.thirddate.datetime": "2021-01",
  "cybersecurity.thirddate.title": "Primo Premio per una Missione di Recupero Dati",
  "cybersecurity.fourthdate.date": "10 febbraio 2022",
  "cybersecurity.fourthdate.datetime": "2022-02",
  "cybersecurity.fourthdate.title": "Reclutamento di sviluppatori IT junior e lead nel nostro team",
  "cybersecurity.fifthdate.date": "26 aprile 2023",
  "cybersecurity.fifthdate.datetime": "2023-04",
  "cybersecurity.fifthdate.title": "Nuove emozionanti sfide internazionali",
  "cybersecurity.banner.title": "I nostri clienti ci adorano",
  "cybersecurity.banner.description": "Siamo un partner di fiducia per le aziende a livello globale, fornendo soluzioni di sicurezza informatica all'avanguardia.",
  "cybersecurity.features.title": "Team di esperti",
  "cybersecurity.features.description": "Il nostro team è composto da professionisti dedicati e esperti di sicurezza informatica con una profonda competenza in vari aspetti della sicurezza informatica.",
  "cybersecurity.careers.title": "Siamo sempre alla ricerca di persone fantastiche per unirsi a noi",
  "cybersecurity.careers.description": "Unisciti al nostro team altamente qualificato e fai una vera differenza nella protezione delle imprese dalle minacce alla sicurezza informatica.",
  "cybersecurity.careers.link": "Vuoi unirti a noi? Candidati qui",

  "cookies-policy": "Politica sui cookie",
  "cookies-policy.title": "Trattamento dei tuoi dati personali",
  "cookies-policy.first-text": "MVE si impegna a garantire che il trattamento dei dati personali effettuato su mve.mc sia conforme:",
  "cookies-policy.first-text-1": "Alla Legge 1.165 del 23/12/1993",
  "cookies-policy.first-text-2": "sulla protezione delle informazioni personali.",
  "cookies-policy.first-text-3": "Il Regolamento Generale sulla Protezione dei Dati (GDPR) - Direttiva 2002/58/CE",
  "cookies-policy.first-text-4": "\"privacy e comunicazioni elettroniche\" del Parlamento europeo e del Consiglio del 12 luglio 2002 riguardante il trattamento dei dati personali e la protezione della privacy nel settore delle comunicazioni elettroniche.",
  "cookies-policy.second-text": "Puoi opporsi al trattamento dei tuoi dati personali rifiutando i cookie.",
  "cookies-policy.first-subtitle": "Dati personali raccolti :",
  "cookies-policy.third-text-1": "Limitate al necessario",
  "cookies-policy.third-text-2": "Vengono conservate per 1 anno",
  "cookies-policy.third-text-3": "Vengono trattate secondo protocolli sicuri",

  "cookies-policy.second-subtitle": "Riguardo i cookie :",
  "cookies-policy.third-subtitle": "Due tipi di cookie sono utilizzati da mve.mc :",
  "cookies-policy.fifth-text-1": "Cookie per ricordare le tue preferenze",
  "cookies-policy.fifth-text-2": "Cookie per misurare l'audience",
  "cookies-policy.more-information": "Per qualsiasi informazione o per esercitare i tuoi diritti riguardo al trattamento dei dati, puoi contattare il nostro Responsabile della Protezione dei Dati (DPO)",
  "cookies-policy.contact": "Contatto",

  "legal": "Legale",
  "legal.title": "Informazioni Legali",
  "legal.text-1": "In conformità con la legge, ti invitiamo a prendere nota delle presenti condizioni di utilizzo e avvisi legali relativi al sito web www.mve.mc",
  "legal.text-2": "L'intero sito è soggetto alla legislazione francese e internazionale sul diritto d'autore e sulla proprietà intellettuale. Tutti i diritti di riproduzione sono riservati, compresi i documenti iconografici e fotografici.",
  "legal.subtitle.first": "Informazioni legali e responsabile della pubblicazione del dispositivo",
  "legal.text-3": "Il sito web www.mve.mc è pubblicato dalla società MVE, una società a responsabilità limitata con un capitale di 150.000 euro, la cui sede legale si trova al 31, Av. Princesse Grace, 98000 Monaco, registrata al Registro del Commercio e delle Società di Monaco sotto il numero RCI 12S05741, numero di IVA intracomunitario FR 81 00009663 5.",
  "legal.subtitle.second": "Indirizzo postale",
  "legal.text-4": "MVE SAM",
  "legal.text-5": "31, Av. Princesse Grace",
  "legal.text-6": "L'Estoril - Bloc A",
  "legal.text-7": "98000 Monaco",
  "legal.subtitle.third": "Contatto",
  "legal.text-8": "Telefono : ",
  "legal.text-9": "Email : ",
  "legal.text-10": "Per qualsiasi domanda o reazione riguardante il sito, vi invitiamo a contattarci via e-mail o tramite il modulo di contatto.",
  "legal.subtitle.fourth": "Proprietà intellettuale",
  "legal.text-11": "Il sito web, così come tutti gli elementi che lo compongono, e in particolare: testi, immagini fisse o animate, composizioni sonore, grafica, software e database, etc. sono di proprietà esclusiva di MVE e sono protetti dalle disposizioni del Codice della Proprietà Intellettuale.",
  "legal.text-12": "Pertanto, qualsiasi riproduzione o rappresentazione di questo sito, in tutto o in parte, qualsiasi estrazione del database con qualsiasi mezzo, senza l'autorizzazione espressa di MVE è proibita. La violazione di queste disposizioni costituirebbe una violazione punibile secondo le disposizioni del Codice della Proprietà Intellettuale e del Codice Penale. I marchi, i nomi commerciali e i loghi presenti su questo sito sono registrati. La loro riproduzione totale o parziale, realizzata da elementi del sito, è proibita.",

  "cookies-pop-up.title": "Avviso sui cookie",
  "cookies-pop-up.text": "Utilizziamo i cookie per garantire la migliore esperienza possibile sul nostro sito web.",
  "cookies-pop-up.accept": "Accetta",
  "cookies-pop-up.read-more": "Leggi di più",

  "security": "Sicurezza",
  "security.title": "La tua sicurezza è la nostra priorità",
  "security.description": "Ci impegniamo a fornire soluzioni di cybersecurity robuste e affidabili. Con noi, proteggi il tuo ambiente digitale e assicurati che le tue operazioni aziendali funzionino in modo fluido e sicuro.",
  "security.first-text": "In un'epoca in cui le minacce informatiche evolvono più velocemente che mai, ci presentiamo come il tuo partner di fiducia, fornendo una fortezza di protezione intorno ai tuoi preziosi asset digitali.",
  "security.features.first.title": "Prevenzione delle minacce e risposta",
  "security.features.first.body": "Il nostro approccio proattivo rileva e mitiga le minacce prima che possano causare danni, e i nostri meccanismi di risposta rapida assicurano una minima interruzione nel caso improbabile di una violazione.",
  "security.features.second.title": "Crittografia dei dati e privacy",
  "security.features.second.body": "Utilizziamo standard di crittografia avanzati per proteggere i tuoi dati sensibili a riposo e in transito, salvaguardando la tua privacy e garantendo la conformità ai requisiti normativi.",
  "security.features.third.title": "Gestione dell'identità e dell'accesso",
  "security.features.third.body": "Ci assicuriamo che solo individui autorizzati abbiano accesso alle tue risorse digitali, aiutando a prevenire l'accesso non autorizzato e potenziali violazioni della sicurezza.",
  "security.subtitle": "Proteggendo la tua Frontiera Digitale",
  "security.second-text": "Affidandoci le tue esigenze di cybersecurity, stai scegliendo la tranquillità. Il nostro team di esperti è all'avanguardia nella cybersecurity, aggiornando costantemente i nostri strumenti e tattiche per contrastare le minacce emergenti.",
  "security.third-text": "Unisciti a noi e rafforza la tua postura di cybersecurity, assicurando che la tua attività prosperi in un ambiente digitale sicuro, stabile e resiliente.",

  "smart-home": "Casa intelligente",
  "smart-home.title": "Trasforma la tua casa in un rifugio connesso e innovativo con standard di sicurezza",
  "smart-home.text-first": "Utilizza l'ultimo aggiornamento e i sistemi di automazione sicuri creando una connettività sicura e user-friendly tra:",
  "smart-home.text-second": "Sistema di illuminazione - Audio/Video - CCTV - Allarme - Biometria - Cinema",
  "smart-home.text-third": "E tutto il sistema personalizzato* per creare la tua infrastruttura IT di casa in conformità con la cybersicurezza",
  "smart-home.text-fourth": "*Certificato Lutron – Manutenzione Crestron: 24/7 per contratto",
  "smart-home.learn-more": "Contatta le vendite",

  "blockchain.title": "La blockchain è il futuro",
  "blockchain.first": "La Blockchain, le Criptovalute e gli NFT non appartengono più al futuro: queste nuove tecnologie stanno per modellare profondamente l'economia nei prossimi anni, candidando il Metaverso come possibile futura rappresentazione di Internet.",
  "blockchain.second": "Cogliamo l'opportunità di diventare un ecosistema web 3.0 di riferimento a livello internazionale, migliorando il nostro vantaggio competitivo mentre catalizziamo e connettiamo opportunità al nostro ecosistema reale. MVE supporta lo sviluppo e l'aggregazione attorno alla tecnologia Blockchain: intendiamo diffondere la Blockchain al pubblico più vasto e favorire l'uso di cripto e tecnologie decentralizzate al fine di generare valore economico, finanziario e culturale per Monaco e il tuo paese, promuovendo una transizione paradigmatica per tutti.",
  "blockchain.third": "Tim Berners-Lee, inventore del World Wide Web (WWW), professore di informatica all'Università di Oxford e al MIT ha detto:",
  "blockchain.fourth": "” Il Web come lo immaginavo, non lo abbiamo ancora visto. Il futuro è ancora molto più grande del passato ”",
  "blockchain.contact": "Contatta le vendite",

  "cloud": "Integratore Cloud",
  "cloud.title": "Sblocca il potere della tua azienda con le nostre soluzioni Cloud",
  "cloud.description": "Come integratori Cloud, ci dedichiamo a fornire soluzioni cloud leader del settore per aiutare a snellire le tue operazioni, migliorare la produttività e garantire il massimo livello di sicurezza.",
  "cloud.blockquote": "Nel mondo degli affari, il cloud non è solo un altro strumento, è un trampolino di lancio per l'innovazione.",
  "cloud.first-text": "Sfrutta il potere trasformativo del cloud per massimizzare il potenziale della tua organizzazione. Che si tratti di archiviazione di dati, distribuzione di software o potenza di calcolo, i nostri servizi cloud ti hanno coperto.",
  "cloud.features.first.title": "Scalabilità infinita",
  "cloud.features.first.body": "Come un cielo in continua espansione, i nostri servizi cloud offrono una scalabilità illimitata per rispondere alla crescita della tua azienda. Espandi o riduci le tue risorse in base alle tue esigenze attuali.",
  "cloud.features.second.title": "Sicurezza incompromessa",
  "cloud.features.second.body": "Garantiamo che i tuoi dati siano sempre sicuri. Le nostre tecniche di crittografia avanzate e i nostri rigorosi protocolli di sicurezza proteggono le tue informazioni sensibili da potenziali minacce.",
  "cloud.features.third.title": "Infrastruttura server affidabile",
  "cloud.features.third.body": "Beneficia della nostra tecnologia server all'avanguardia che garantisce alte prestazioni, affidabilità e uptime, assicurando che le tue operazioni non vengano mai interrotte.",
  "cloud.subtitle": "Sfrutta il potere dell'integrazione",
  "cloud.second-text": "Vivi l'impatto trasformativo dell'integrazione delle tue applicazioni e sistemi nel cloud. Non è solo un archiviazione, è un nuovo modo di comprendere e snellire le operazioni aziendali. Iniziamo insieme il viaggio verso un futuro più intelligente e integrato nel cloud.",

  "captcha": "Captcha",
  "captcha.loading": "Caricamento...",

  "mail.sendSuccess": "Il tuo messaggio è stato inviato con successo",
  "mail.sendError": "Si è verificato un errore",
  "mail.send": "Messaggio inviato",
  "mail.notSend": "Errore",

  "fonds-bleu.quote": "Il Fond Bleu Extended Monaco è un vero trampolino di lancio per la transizione digitale delle imprese",
  "fonds-bleu.role": "CEO presso MVE",
  "fonds-bleu": "Fond Bleu Monaco",
  "fonds-bleu.title": "Monaco lancia il suo fondo di supporto per la transizione digitale",
  "fonds-bleu.text.first": "Il Fond Bleu è un fondo di sostegno istituito dal Governo nell'ambito del programma Extended Monaco. Ha lo scopo di promuovere la ripresa economica attraverso mezzi digitali, puntando a tre obiettivi:",
  "fonds-bleu.text.second": "Aumentare la maturità digitale delle imprese monegasche;",
  "fonds-bleu.text.third": "Promuovere un ecosistema favorevole all'economia digitale;",
  "fonds-bleu.text.fourth": "Sostenere il settore dei servizi digitali monegasco per le imprese.",
  "fonds-bleu.text.fifth": "Basato su un principio di cofinanziamento, il Fond Bleu supporta i progetti di transizione digitale che rientrano in questi obiettivi.",
  "fonds-bleu.learn-more": "Scopri di più sul Fond Bleu",
  "fond-bleu.stats.label.first": "Piano di supporto",
  "fond-bleu.stats.label.second": "Fondata da",
  "fond-bleu.stats.label.third": "Copertura",

  "devops": "DevOps & Sviluppo",
  "devops.title": "Ottimizza la tua presenza digitale con le nostre soluzioni integrate",
  "devops.description": "Specializzati in DevOps, uniamo sviluppo e operazioni per soluzioni senza soluzione di continuità, sia per il web, il mobile o il monitoraggio dei tuoi sistemi.",
  "devops.blockquote": "La sinergia tra sviluppo e operazioni è il nostro mantra. Entra nel mondo delle prestazioni e dell'affidabilità con DevOps.",
  "devops.first-text": "Dai vita alle tue visioni con un approccio DevOps. Dalla creazione di applicazioni all'ottimizzazione del sistema, copriamo l'intero spettro.",
  "devops.features.first.title": "Soluzioni su misura",
  "devops.features.first.body": "Trasforma le tue idee in realtà ad alte prestazioni, su misura per le tue esigenze e pronte per il futuro.",
  "devops.features.second.title": "Monitoraggio proattivo",
  "devops.features.second.body": "Rimani informato e pronto a reagire con i nostri avanzati strumenti di monitoraggio.",
  "devops.features.third.title": "Coesione e automazione",
  "devops.features.third.body": "Con un'approfondita integrazione DevOps, garantiamo una transizione fluida tra sviluppo e operazioni, massimizzando sia la produttività che la stabilità.",
  "devops.subtitle": "Il potere di DevOps al tuo servizio",
  "devops.second-text": "Vivi l'armonia tra creazione, distribuzione e monitoraggio. Con DevOps, abbraccia una metodologia agile incentrata sulla collaborazione e l'efficienza.",

  "careers.contact.title": "Carriere",
  "careers.contact.subtitle": "Cerchi la tua prossima opportunità di carriera?",
  "careers.contact.open": "Aperto",
  "careers.contact.close": "Chiuso",
  "careers.contact.first": "Disegnatore Autocad CFO CFA",
  "careers.contact.first.description": "",
  "careers.contact.second": "Auditor Pentest",
  "careers.contact.second.description": "Requisiti: Conoscenza ISO 27001, Esperienza PASSI sarebbe un plus",
  "careers.errorMessage.title": "Errore",
  "careers.errorMessage.description": "Offerta non disponibile al momento.",

  "blog.description": "Trovate tutte le ultime notizie da MVE Monaco e le ultime informazioni nei settori della cybersecurity, dell'intelligenza artificiale e della blockchain.",
  "blog.download": "Scaricare",
  "blog.title.wealthtech2024": "Conferenza WEALTHTECH Monaco 2024",
  "blog.description.wealthtech2024": "Intelligenza artificiale e investimenti: le nuove frontiere della finanza.",
  "blog.subdescription.wealthtech2024": "Il 17 ottobre si è tenuta la 3a edizione della conferenza WealthTech di Monaco, organizzata dall'Associazione Monegasca delle Attività Finanziarie (AMAF) in collaborazione con il Governo del Principe. MVE Monaco ha condiviso la sua esperienza durante questo evento sulle applicazioni dell'IA nel settore finanziario e del Wealth Management.",
  "blog.date.wealthtech2024": "Giovedì 17 Ottobre 2024",

  "login.title": "Accesso",
  "login.username": "Nome utente",
  "login.password": "Password",
  "login.remember-me": "Ricordami",
  "login.sign-in": "Accedi",
  "login.loading": "Caricamento...",

  "layouts.navigation-dashboard": "Pannello di controllo",
  "layouts.navigation-users": "Utenti",
  "layouts.navigation-articles": "Articoli",
  "layouts.settings": "Impostazioni",
  "layouts.home": "Home",
  "layouts.sign-out": "Disconnetti",

  "admin.dashboard.overview": "Pannello di controllo",
  "admin.articles": "Articoli",
  "admin.articles.title": "Articoli",
  "admin.users": "Utenti",
  "admin.users.title": "Utenti",
  "admin.users.description": "Gestione utenti",
  "admin.users.name": "Nome",
  "admin.users.email": "Email",
  "admin.users.latest-update": "Ultimo aggiornamento",
  "admin.title": "Titolo",
  "admin.published": "Pubblicato",
  "admin.users.a-few-seconds-ago": "Qualche secondo fa",
  "admin.users.first-part-minute": "Un",
  "admin.users.second-part-minute": "minuti fa",
  "admin.users.first-part-hour": "Un",
  "admin.users.second-part-hour": "ore fa",
  "admin.users.first-part-day": "Un",
  "admin.users.second-part-day": "giorni fa",
  "admin.users.edit": "Modifica",
  "admin.users.add-a-user": "Aggiungi un utente",
  "admin.users.modify-a-user": "Modifica un utente",
  "admin.users.delete": "Elimina",
  "admin.users.update": "Aggiorna",
  "admin.news.title": "Notizie",
  "admin.news.description": "Gestione notizie",
  "admin.news.title-news": "Titolo",
  "admin.news.published": "Pubblicato",
  "admin.sales.edit": "Modifica",
  "admin.sales.delete": "Elimina",
  "admin.news.edit-article": "Modifica articolo",
  "admin.sales.submit": "Invia",
  "admin.news.add-article": "Aggiungi un articolo",
  "admin.news.description-news": "Descrizione",
  "admin.news.subtitle-news": "Sottotitolo",
  "admin.sales.modify-images": "Modifica immagini",
  "admin.berth.drop-items-here": "Rilascia gli elementi qui",
  "admin.berth.upload-files": "Carica file"

}
